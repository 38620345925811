.error-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    text-align: center;
    background-color: #f8d7da;
    color: #721c24;
}

.error-code {
    font-size: 10rem;
    font-weight: bold;
}

.error-message {
    font-size: 5rem;
    margin: 1rem 0;
}

.error-description {
    font-size: 2rem;
    margin-bottom: 2rem;
}

.error-button {
    padding: 0.5rem 1rem;
    font-size: 1rem;
    color: #fff;
    background-color: #721c24;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    text-decoration: none;
}

.error-button:hover {
    background-color: #a71d2a;
}