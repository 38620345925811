	/*
  	Flaticon icon font: Flaticon
  	Creation date: 16/05/2018 10:21
  	*/

@font-face {
  font-family: "Flaticon";
  src: url("../fonts/Flaticon.eot");
  src: url("../fonts/Flaticon.eot?#iefix") format("embedded-opentype"),
       url("../fonts/Flaticon.woff") format("woff"),
       url("../fonts/Flaticon.ttf") format("truetype"),
       url("../fonts/Flaticon.svg#Flaticon") format("svg");
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: "Flaticon";
    src: url("../fonts/Flaticon.svg#Flaticon") format("svg");
  }
}

[class^="flaticon-"]:before, [class*=" flaticon-"]:before,
[class^="flaticon-"]:after, [class*=" flaticon-"]:after {   
  font-family: Flaticon;
  font-size: 20px;
  font-style: normal;
  margin-left: 0px;
}

.flaticon-placeholder:before { content: "\f100"; }
.flaticon-search:before { content: "\f101"; }
.flaticon-gps-fixed-indicator:before { content: "\f102"; }
.flaticon-list-with-dots:before { content: "\f103"; }
.flaticon-transport:before { content: "\f104"; }
.flaticon-pills:before { content: "\f105"; }
.flaticon-building:before { content: "\f106"; }
.flaticon-travel:before { content: "\f107"; }
.flaticon-restaurant:before { content: "\f108"; }
.flaticon-location-on-road:before { content: "\f109"; }
.flaticon-networking:before { content: "\f10a"; }
.flaticon-lightbulb-idea:before { content: "\f10b"; }