@keyframes slideFromLeft {
    from {
        left: -100%;
    }
    to {
        left: 50vw;
        transform: translateX(-50%);
    }
}

@keyframes slideFromRight {
    from {
        left: 100vw;
    }
    to {
        left: 50vw;
        transform: translateX(-50%);
    }
}

.question-card {
    position: absolute;
    top: 30vh;
    display: none;
    width: 100%;
    height: 50vh;
    max-width: 600px;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.question-card.right {
    animation: slideFromRight 0.5s ease;
    display: flex;
}

.question-card.left {
    animation: slideFromLeft 0.5s ease;
    display: flex;
}

.question-input {
    width: 100%;
    padding: 10px;
    font-size: 1.5rem;
    color: black;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.question-label {
    font-size: 1.5rem;
    color: #555;
    margin-bottom: 5px;
}