.navbar-buttons {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin-block: auto;
    column-gap: 30px;
    padding: 0;
    margin: 0;
}

.navbar-buttons a {
    color: var(--button-color);
}
