.questions-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    background-color: #f0f4f8;
    min-height: 100vh;
    overflow: hidden;
    background: url(../assets/images/welcome-hero/banner.jpg)no-repeat;
    background-position:center;
    background-size:cover;
}

.title {
    font-size: 2.5rem;
    color: #333;
    margin-bottom: 20px;
}

.questions-container {
    width: 100%;
    height: 50vh;
    max-width: 600px;
}

.question-item {
    margin-bottom: 15px;
}

.navigation-buttons {
    position: absolute;
    bottom: 5%;
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.navigation-buttons button {
    margin: 0 10px;
    padding: 10px 20px;
    border: none;
    font-size: 1.2rem;
    border-radius: 4px;
    background-color: var(--button-color);
    color: #fff;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.navigation-buttons button:focus {
    color: #fff;
}

.navigation-buttons button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

.navigation-buttons button:enabled:hover {
    background-color: var(--button-color-hover);
    color: #fff;
}