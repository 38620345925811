.projections-page {
    text-align: center;
    padding: 20px;
    max-height: 90vh;
    overflow-y: auto;
    margin: 0 auto;
}

h1 {
    font-size: 2.5rem;
    margin-bottom: 20px;
    color: #333;
}

.news-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 40px;
    max-height: 500px;
    overflow-y: auto;
}

.news-navigation {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 600px;
    margin-bottom: 20px;
}

.news-navigation button {
    padding: 10px 20px;
    font-size: 1rem;
    background-color: var(--button-color);
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.news-navigation button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

.news-navigation button:hover:not(:disabled) {
    background-color: var(--button-color-hover);
}

.news-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* 2 columns */
    gap: 20px; /* Space between articles */
    width: 100%;
    max-width: 1200px;
}

.news-article {
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    text-align: left;
    transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.news-article:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.news-article h3 {
    font-size: 1.5rem;
    margin-bottom: 10px;
    color: #007bff;
}

.news-article p {
    font-size: 1rem;
    color: #555;
    line-height: 1.6;
    margin-bottom: 15px;
}

.read-more {
    display: inline-block;
    padding: 10px 15px;
    background-color: #007bff;
    color: #fff;
    text-decoration: none;
    border-radius: 5px;
    transition: background-color 0.3s ease;
}

.read-more:hover {
    background-color: var(--button-color-hover);
}

.chatbot-section {
    margin-top: 40px;
    padding: 20px;
    background: #f8f9fa;
    border-radius: 10px;
    box-shadow: 0
     4px 8px rgba(0, 0, 0, 0.1);
}

.chatbot-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
}

.chatbot-container input {
    width: 100%;
    max-width: 500px;
    padding: 10px;
    font-size: 1.5rem;
    border: 1px solid #ccc;
    border-radius: 5px;
    outline: none;
}

.chatbot-container button {
    padding: 10px 20px;
    font-size: 1rem;
    background-color: var(--button-color);
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.chatbot-container button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

.chatbot-container button:hover:not(:disabled) {
    background-color: var(--button-color-hover);
}

.chatbot-response {
    width: 100%;
    max-width: 800px;
    margin-top: 20px;
    padding: 15px;
    background: #fff;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    text-align: left;
    font-size: 1rem;
    color: #333;
    max-height: 300px;
    overflow-y: auto;
    
}

.back-button {
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #dc3545;
    color: white;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s ease;
}

.back-button:hover {
    background-color: #c82333;
}


.chatbot-input-container {
    display: flex;
    gap: 10px; /* Adds space between the input and button */
    align-items: center; /* Aligns input and button vertically */
    width: 100%; /* Makes the container take up the full width of its parent */
    max-width: 800px; /* Sets a maximum width for the container */
    margin: 0 auto; /* Centers the container horizontally */
}

.chatbot-input-container input {
    flex: 1; /* Allows the input to take up remaining space */
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.chatbot-input-container button {
    padding: 10px 20px;
    font-size: 16px;
    background-color: var(--button-color);
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.chatbot-input-container button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}