.signup-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100vh;
    background: url(../assets/images/welcome-hero/banner.jpg)no-repeat;
    background-size: cover;
    background-position: center;
    padding: 20px;
}

.signup-container h2 {
    font-size: 2.5rem;
    margin-bottom: 20px;
    color: #333;
}

.signup-container form {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 400px;
    background-color: #fff;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.signup-container form div {
    margin-bottom: 20px;
}

.signup-container form label {
    font-size: 1.1rem;
    margin-bottom: 5px;
    color: #555;
}

.signup-container form input {
    width: 100%;
    padding: 12px;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 5px;
    transition: border-color 0.3s;
}

.signup-container form input:focus {
    border-color: var(--button-color);
    outline: none;
}

.signup-container form button {
    padding: 12px;
    font-size: 1.2rem;
    color: #fff;
    background-color: var(--button-color);
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.signup-container form button:hover {
    background-color: var(--button-color-hover);
}