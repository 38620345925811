.login-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    background: url(../assets/images/welcome-hero/banner.jpg)no-repeat;
    background-size: cover;
    background-position: center;
    padding: 20px;
}

.login-form {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 400px;
    background-color: #fff;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.login-form h1 {
    font-size: 2.5rem;
    margin-bottom: 20px;
    color: #333;
    text-align: center;
}

.login-form input {
    width: 100%;
    padding: 12px;
    font-size: 1.4rem;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-bottom: 20px;
    transition: border-color 0.3s;
}

.login-form input:focus {
    border-color: var(--button-color);
    outline: none;
}

.login-form button {
    padding: 12px;
    font-size: 1.2rem;
    color: #fff;
    background-color: var(--button-color);
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.login-form button:hover {
    background-color: var(--button-color-hover);
}

.login-form p {
    margin-top: 20px;
    text-align: center;
    color: #555;
}

.login-form p a {
    color: var(--button-color);
    text-decoration: none;
}

.login-form p a:hover {
    text-decoration: underline;
}