.advisor-page {
    text-align: center;
    padding: 20px;
}

.chatbox {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.messages {
  width: 100%;
  max-width: 100vw;
  margin-bottom: 20px;
  padding: 10px;
  background-color: #f0f0f0;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  max-height: 300px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.ai-message,
.user-message {
  max-width: 80%;
  padding: 10px;
  border-radius: 5px;
  display: inline-block;
  word-wrap: break-word;
  margin-bottom: 10px;
}

.ai-message {
  background-color: #ffffff; /* New background color for AI messages */
  color: #333;
  text-align: left;
}

.user-message {
  background-color: #e1e1e1;
  color: #333;
  align-self: flex-end; /* Align User messages to the right */
  text-align: left;
}

.chat-input {
  display: flex;
  width: 100%;
  max-width: 600px;
  gap: 10px;
  margin-top: 20px;
}

.chat-input input {
  width: 80%;
  padding: 10px;
  font-size: 1.5rem;
  border-radius: 5px;
  border: 1px solid #ccc;
}

.chat-input button {
  padding: 10px 20px;
  font-size: 16px;
  background-color: var(--button-color);
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.chat-input button:hover {
  background-color: var(--button-color-hover);
}


.loading-message {
  font-style: italic;
  color: #888;
  margin-top: 10px;
  text-align: center;
}

.chat-input input:disabled,
.chat-input button:disabled {
  background-color: #d3d3d3;
  cursor: not-allowed;
}

.news-button {
  margin-top: 20px;
  padding: 10px 20px;
  font-size: 18px;
  background-color: var(--button-color);
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 5px;
}

.visualize-button:hover {
  background-color: var(--button-color-hover);
}
