.header-navbar {
    padding: 0;
    margin: 0;
}

.header-title-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
    justify-content: space-between;
    padding-inline: 5rem;
}

.navbar-brand {
    font-size: 1.5rem;
    font-weight: bold;
    color: #333;
    margin-left: 1rem;
}

.navbar:before, .navbar:after, .header-title-container:before, .header-title-container:after {
    content: none;
}