*{
    padding: 0;
    margin: 0;
}

*{
	-webkit-box-sizing:border-box;
	-moz-box-sizing:border-box;
	-o-box-sizing:border-box;
	-ms-box-sizing:border-box;
	box-sizing:border-box;
}
body{
	font-family: 'Poppins', sans-serif;
	font-size:14px;
	color:#a09e9c;
    text-transform:initial;
    max-width:1920px;
    margin:0 auto;
	overflow-x:hidden;
    overflow-y: hidden;
}


h1,h2,h3,h4,h5,h6 { 
	margin: 0;
	color:#343a3f;
    font-size: 18px;
    font-weight: 500;
    text-transform: capitalize;
}
p {
	margin: 0;
	color:#767f86;
	font-size:14px;
    line-height: 1.8;
    text-transform: initial;
}
img{border:none;max-width:100%; height:auto;}
ul{
	padding: 0;
    margin: 0 auto;
    list-style: none;
}
ul li {
	list-style: none;
	
}
select,input,textarea,button{box-shadow:none;outline:0!important;}
button {background: transparent;border: 0;font-size: 12px;}

html,body{height: 100%;}

[placeholder]:focus::-webkit-input-placeholder {
  -webkit-transition: opacity 0.3s 0.3s ease; 
  -moz-transition: opacity 0.3s 0.3s ease; 
  -ms-transition: opacity 0.3s 0.3s ease; 
  -o-transition: opacity 0.3s 0.3s ease; 
  transition: opacity 0.3s 0.3s ease; 
  opacity: 0;
}

/* section-header */
.section-header{
    position: relative;
    text-align: center;
}
.section-header h2{
    color: #505866;
    font-size:24px;
    font-weight: 500;
    margin-bottom: 24px;
    text-transform: uppercase;
    letter-spacing: 1.3px;
}
.section-header h2 span{text-transform: lowercase;}
.section-header p{
    font-size:16px;
}
/* section-header */
.fix{position: relative;clear: both;}
/*=============Style css=========*/


/*-------------------------------------
        2. Header-top
--------------------------------------*/
.header-top{border-bottom:1px solid #f6f8fa;}

.header-top ul li {display: inline-block;}

.header-top-left li,.header-top-right li  {display: inline-block;}

/*.select-opt*/
.select-opt,.header-top-contact{
    padding: 10px 18px;
    border-right: 1px solid #f6f8fa;
}
.header-top-contact{
    border-right: 0;
    border-left: 1px solid #f6f8fa;
    font-size: 12px;
}
.select-opt select {
    border: none;
    cursor:pointer;
    color:#a7b0ba;
    font-size: 12px;
    font-weight: 300;
}
.select-opt option{
    color:#a7b0ba;
    text-transform: uppercase;
}
.select-opt a span.lnr-magnifier:before {
    font-size: 12px;
    color: #373939;
}
.select-opt a span.lnr-magnifier:before {
    font-size: 12px;
    color: #373939;
}
/*.select-opt*/

.header-top-right ul li a{
    color:#a7b0ba;
    font-size: 12px;
    font-weight: 300;
}


/*-------------------------------------
        3. Top-area
--------------------------------------*/
nav.navbar.bootsnav {
    background-color:#fff;
    border-bottom: transparent;
}
.wrap-sticky nav.navbar.bootsnav.sticked {background-color: #fff;box-shadow: 0 2px 5px rgba(0,0,0,.2);}

/*.navbar-brand*/
.navbar-header a.navbar-brand,.navbar-header a.navbar-brand:hover,.navbar-header a.navbar-brand:focus {
    display: inline-block;
    color: #1b1e20;
    font-size: 20px;
    font-weight: 700;
    padding: 34px 0px;
    text-transform:capitalize;
}
.navbar-header a.navbar-brand span.colored{display: inline-block;color:#ff545a;text-transform:capitalize;}
.navbar-header a.navbar-brand span{display: inline-block;text-transform:capitalize;}

.wrap-sticky nav.navbar.bootsnav.sticked .navbar-header a.navbar-brand,.wrap-sticky nav.navbar.bootsnav.sticked .navbar-header a.navbar-brand:hover,.wrap-sticky nav.navbar.bootsnav.sticked .navbar-header a.navbar-brand:focus {padding:30px 0px;}
/*.navbar-brand*/

/*.nav li*/
nav.navbar.bootsnav ul.nav > li > a {
    color: #859098;
    font-size: 14px;
    font-weight: 500;
    text-transform:uppercase;
    -webkit-transition: 0.3s linear; 
    -moz-transition: 0.3s linear; 
    -ms-transition: 0.3s linear; 
    -o-transition: 0.3s linear; 
    transition: 0.3s linear;
}
nav.navbar.bootsnav ul.nav > li > a {padding:34px 21px;}
nav.navbar.bootsnav ul.nav > li > a:hover,nav.navbar.bootsnav ul.nav > li > a:focus{color: #ff545a;}
nav.navbar.bootsnav ul.nav > li.active>a {color: #ff545a;}

nav.navbar.bootsnav ul.nav > li.dropdown > a.dropdown-toggle:after {
    content: "";
}
nav.navbar.bootsnav ul.nav > li.dropdown span {
    font-size: 8px;
    margin-left: 15px;
}
.wrap-sticky nav.navbar.bootsnav.sticked ul.nav > li > a{padding:34px 21px;}
/*.nav li*/

/*.menu-ui-design*/
.menu-ui-design{overflow-y:scroll;height: 350px;}
.menu-ui-design::-webkit-scrollbar {
    width:5px;
}
.menu-ui-design::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 8px #000;
}
.menu-ui-design::-webkit-scrollbar-thumb {
  background-color: #ff545a;
}
/*.menu-ui-design*/

/*.navbar-toggle */
nav.navbar.bootsnav .navbar-toggle {
    position: relative;
    background-color: transparent;
    border: 1px solid #ff545a;
    padding: 10px;
    top: 0;
}
nav.navbar.bootsnav .navbar-toggle i{color: #ff545a;}
/*.navbar-toggle */

/*-------------------------------------
        4.  Welcome-hero
--------------------------------------*/
.welcome-hero {
    position: relative;
    background:url(../images/welcome-hero/banner.jpg)no-repeat;
    background-position:center;
    background-size:cover;
    text-align: center;
    height: 100vh;
    z-index: 1;
}

.welcome-hero:before{
    position: absolute;
    content: " ";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background:rgba(65,73,89,.65);
    z-index: -1;
}
.welcome-hero-txt { padding-top: 155px;}

.welcome-hero-txt h2 {
    font-size: 48px;
    color: #fff;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 2.4px;
    line-height: 1.4;
}
.welcome-hero-txt p {
    font-size: 18px;
    color: #fcfcfc;
    margin-top: 25px;
}

/* .welcome-hero-serch-box{margin-top: 78px;display: flex;} */
.welcome-hero-serch-box {
    display: flex;
    justify-content: center;
    align-items: center;
}
.welcome-hero-form {
    display: flex;
    background: #fff;
    height: 70px;
    border-radius: 3px;
    width: 85%;
}
.single-welcome-hero-form {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 50%;
    padding: 0 30px;
}
.single-welcome-hero-form:first-child{border-right: 1px solid #edeff1;}
.single-welcome-hero-form input {
    margin-left: 10px;
    height: 70px;
    width: 300px;
    border: 0;
    background: transparent;
}
.single-welcome-hero-form input[type="text"]{
    font-size: 14px;
    color: #859098;
    text-transform: capitalize;
    font-weight: 500;
}
.welcome-hero-form-icon {
    position: absolute;
    top: 20px;
    right: 30px;
    color: #252d32;
}
.welcome-hero-btn {
    display:  flex;
    justify-content:  center;
    align-items:  center;
    font-size:  14px;
    color:  #fff;
    width:  170px;
    height:  70px;
    background:  #ff545a;
    text-transform:  capitalize;
    margin-top: 40px;
    border-radius: 10px;
    -webkit-transition: 0.3s linear; 
    -moz-transition: 0.3s linear; 
    -ms-transition: 0.3s linear; 
    -o-transition: 0.3s linear; 
    transition: 0.3s linear;
}
.welcome-hero-btn:hover{/*background: #fd4043;*/background: #f43032;}

.welcome-hero-btn svg {
    width:  14px;
    height:  auto;
    margin-left:  12px;
}

/*-------------------------------------
        5. List-topuics
--------------------------------------*/
.list-topics-content {
    position: relative;
    top: -98px;
    z-index: 1;
}
.list-topics-content ul li { display: inline-block;}

.single-list-topics-content{
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    width: 205px;
    height: 170px;
    background:#fff;
    border-radius: 3px;
    margin-right: 20px;
    box-shadow: 0 0px 10px rgba(71,71,71,.2);
    -webkit-transition: .3s linear; 
    -moz-transition:.3s linear; 
    -ms-transition:.3s linear; 
    -o-transition:.3s linear;
    transition: .3s linear;
}
.single-list-topics-content h2>a { margin: 13px 0;}
/*.single-list-topics-content:last-child{margin-right: 0;}*/

.single-list-topics-icon [class^="flaticon-"]:before,.single-list-topics-icon [class*=" flaticon-"]:before,.single-list-topics-icon [class^="flaticon-"]:after,.single-list-topics-icon [class*=" flaticon-"]:after {font-size: 45px;color:#343a3f;}
.single-list-topics-content:hover .single-list-topics-icon [class^="flaticon-"]:before,.single-list-topics-content:hover .single-list-topics-icon [class*=" flaticon-"]:before,.single-list-topics-content:hover .single-list-topics-icon [class^="flaticon-"]:after,.single-list-topics-content:hover .single-list-topics-icon [class*=" flaticon-"]:after {color:#fff;}

.single-list-topics-content:hover h2>a,.single-list-topics-content:hover p{color: #fff!important;}
.single-list-topics-content:hover{
    color: #fff;
    background:#ff545a;
    box-shadow: 0 5px 10px rgba(71,71,71,.4);
}

/*-------------------------------------
        6. Works 
--------------------------------------*/
.works{padding: 0 0 90px;}
.works-content {margin-top: 73px;}

.single-how-works{
    text-align: center;
    padding:50px 42px;
    border-radius: 3px;
    box-shadow: 0 0px 5px rgba(71,71,71,.2);
    margin-bottom: 30px;
    -webkit-transition: .3s linear; 
    -moz-transition:.3s linear; 
    -ms-transition:.3s linear; 
    -o-transition:.3s linear;
    transition: .3s linear;
}

.single-how-works-icon {
    display: inline-block;
    color: #50616c;
    width: 80px;
    height: 80px;
    line-height: 80px;
    background: #eef2f6;
    border-radius: 50%;
}
.single-how-works h2 a {
    font-size:  18px;
    margin: 35px 0 20px;
}
.single-how-works h2 a span {text-transform:  lowercase;}
.single-how-works p {margin-bottom: 25px;text-transform: initial;}

.single-how-works-icon [class^="flaticon-"]:before,.single-how-works-icon [class*=" flaticon-"]:before,.single-how-works-icon [class^="flaticon-"]:after,.single-how-works-icon [class*=" flaticon-"]:after {font-size: 35px;}
.single-how-works:hover .single-how-works-icon [class^="flaticon-"]:before,.single-how-works:hover .single-how-works-icon [class*=" flaticon-"]:before,.single-how-works:hover .single-how-works-icon [class^="flaticon-"]:after,.single-how-works:hover .single-how-works-icon [class*=" flaticon-"]:after {color:#ff545a;}

.welcome-hero-btn.how-work-btn {
    display: inline-block;
    margin: 0;
    width: 100px;
    height: 35px;
    font-size: 12px;
    background: transparent;
    color: #767f86;
    border: 1px solid #d3d6d9;
    border-radius: 3px;
}

.single-how-works:hover h2 a,.single-how-works:hover p{color: #fff;}
.single-how-works:hover .single-how-works-icon{background: #fff;}
.single-how-works:hover .welcome-hero-btn.how-work-btn{background: #fff;color: #ff545a;}
.single-how-works:hover{box-shadow: 0 0px 10px rgba(71,71,71,.4);background: #ff545a;}

/*-------------------------------------
        7. Explore
--------------------------------------*/
.explore{
    padding:117px 0 95px;
    background: #f8fafb;
}
.explore-content{margin-top: 78px;}

.single-explore-item {
    background: #fff;
    border: 1px solid #edeff1;
    border-radius: 3px;
    margin-bottom: 25px;
    -webkit-transition: .3s linear; 
    -moz-transition:.3s linear; 
    -ms-transition:.3s linear; 
    -o-transition:.3s linear;
    transition: .3s linear;
}
.single-explore-img{position:relative;overflow: hidden;}
.single-explore-img:before {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(162,172,177,.2);
}
.single-explore-img-info {
    position: absolute;
    bottom:-20px;
    left: 0;
    width: 100%;
    opacity:0;
    visibility:hidden;
    -webkit-transition: .3s linear;
    -moz-transition: .3s linear;
    -ms-transition: .3s linear;
    -o-transition: .3s linear;
    transition: .3s linear;
}
.single-explore-item:hover .single-explore-img-info{
    opacity:1;
    visibility:visible;
    bottom:0px
}
.single-explore-img-info button{
    position: absolute;
    bottom: 15px;
    left: 15px;
    width: 83px;
    height: 21px;
    line-height: 21px;
    background: #ff545a;
    border-radius: 3px;
    color: #fcfcfc;
    text-transform: capitalize;
    text-align: center;
    font-size: 12px;
}
.single-explore-image-icon-box {
    text-align: right;
    position: absolute;
    bottom: 10px;
    right:  10px;
}
.single-explore-image-icon-box ul li {
    display:  inline-block;
    width: 30px;
    height:  28px;
    line-height:  28px;
    background: #252d32;
    text-align:  center;
    margin-left:  5px;
    color:  #cbcccd;
}
.single-explore-image-icon-box ul li:hover i{color: #267dff;}

.single-explore-txt {
    padding: 26px 25px 24px 15px;
}
.single-explore-txt.bg-theme-1 .explore-rating{background: #70a9ff;}
.single-explore-txt.bg-theme-2 .explore-rating{background: #00c61c;}
.single-explore-txt.bg-theme-3 .explore-rating{background: #ffcc5d;}
.single-explore-txt.bg-theme-4 .explore-rating{background: #bd70ff;}
.single-explore-txt.bg-theme-5 .explore-rating{background: #ff7a40;}

.explore-rating-price,.explore-rating-price a {
    font-size: 12px;
    color: #777f85;
    text-transform: capitalize;
    font-weight: 400;
    margin: 15px 0 20px;
}
.explore-rating-price a {margin:0;}
.explore-rating {
    display: inline-block;
    width: 32px;
    height: 20px;
    line-height: 20px;
    color: #fff;
    text-align: center;
    border-radius: 3px;
    font-weight: 500;
    margin-right: 10px;
}
.explore-price {color: #f63138;}
.explore-price-box {
    display: inline-block;
    padding: 0 10px;
    margin: 0 8px;
    border-left: 1px solid #dde0e4;
    border-right: 1px solid #dde0e4;
}
.explore-person {
    padding-bottom: 28px;
    border-bottom: 1px solid #e1e5eb;
}
.explore-person-img{
    width: 40px;
    height: 40px;
    border-radius: 50%;
}
.explore-person p {font-size: 12px;}
.explore-open-close-part {
    margin-top: 20px;
}
.close-btn {
    color: #f63138;
    text-transform: capitalize;
}
.close-btn.open-btn {color: #00c437;}
.explore-map-icon{text-align: right;}
.explore-map-icon a svg {
    width: 12px;
    height: 14px;
    margin-left: 23px;
    color: #767f86;
}
.explore-map-icon a svg:hover{color: #f63138;}
.single-explore-txt.bg-theme-2 .explore-map-icon a svg:hover{color: #00c437;}
.single-explore-item:hover{box-shadow: 0 10px 20px rgba(21,19,19,.2);}

/*-------------------------------------
        8. Reviews
--------------------------------------*/
.reviews{padding:117px 0 75px;}
.reviews-content {margin-top:36px;}

/*single-testimonial-box */
.single-testimonial-box  {
    padding: 50px 30px;
    box-shadow: 0 0px 5px rgba(71,71,71,.2); 
    overflow-x:hidden;
    -webkit-transition: .3s; 
    -moz-transition:.3s; 
    -ms-transition:.3s; 
    -o-transition:.3s;
    transition: .3s;
}
.single-testimonial-box:hover{box-shadow:0 10px 20px rgba(21,19,19,.2);}
.slick-current .single-testimonial-box{box-shadow:0 10px 20px rgba(21,19,19,.2);}
/*testimonial-description*/
.single-testimonial-box{
    width: 404px;
    background:#fff;
}
/* testimonial-info */
.testimonial-info {
    display: flex;
    align-items: center;
    text-transform:capitalize;
}
.testimonial-img {
    position: relative;
    top: 11px;
    margin-right: 5px;
    border-radius:50%;
    -webkit-transition: .3s; 
    -moz-transition:.3s; 
    -ms-transition:.3s; 
    -o-transition:.3s;
    transition: .3s;
}
.testimonial-person {
    margin-left: 15px;
    margin-top: 11px;
}
.testimonial-person h2 {
    color: #505866;
    font-size: 18px;
}
.testimonial-person h4 {
    color: #a2a5ab;
    font-size: 14px;
    font-weight: 400;
    margin-top: 10px;
}
.testimonial-person-star i {
    color: #ffda2b;
    margin: 9px 4px 0 0;
}/* testimonial-info */


/* testimonial-comment */
.testimonial-comment {
    margin-top: 18px;
}
.testimonial-comment p{
    color: #8d939e;
    font-size: 14px;
    font-weight: 300;
}/* testimonial-comment */


/*.slick-slide*/
.slick-initialized .slick-slide {
    display: block;
    padding: 40px 0;
}
.slick-slide.slick-cloned {outline: 0!important;}
.slick-slide {
  margin: 0px 10px;
}
.slick-slide {
  transition: all ease-in-out .3s;
  opacity: .5;
}
.slick-active {
  opacity: .5;
}
.slick-current {
  opacity: 1;
}

@keyframes example {
    0%   {bottom:0px;}
    100%  {bottom:7px;}
}
